import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceGrinBeamSweat } from "@fortawesome/free-regular-svg-icons";
import { faBriefcase, faEarthAsia, faUserGraduate, faSchool } from "@fortawesome/free-solid-svg-icons";
import ChillImg from "../../assets/imgs/images.jpg";
import Img from "../../components/img";

function About() {
  useEffect(() => {
    document.title = "Trang Chủ";
  }, []);

  return (
    <div className='font-bold text-neutral-800 w-full pb-4'>
      <div className='mb-3 flex text-3xl gap-2 font-bold'>
        <div className='bg-neutral-800 h-[36px] w-2'></div>
        <h2>About 💤</h2>
      </div>
      <div className='mt-4 font-semibold md:grid grid-cols-2 gap-x-4'>
        <div>
          <div>
            <h3 className='mb-2 text-xl mt-6'>
              <span>⬤</span> Tôi học lập trình như thế nào? 🤔
            </h3>
            <p className='text-slate-800/90 text-pretty'>
            - Tôi đam mê khám phá và khắc phục các lỗ hổng bảo mật như SQL Injection, XSS, và CSRF, cùng với việc triển khai các biện pháp bảo vệ tiên tiến. Tôi thường xuyên nghiên cứu các vấn đề như xác thực không đầy đủ, lộ thông tin nhạy cảm, và quyền truy cập không chính xác. Đam mê và sự tò mò luôn dẫn dắt tôi trong hành trình cải thiện an toàn thông tin, với niềm tin rằng kiên nhẫn và nỗ lực sẽ giúp tôi vượt qua mọi thách thức trong lĩnh vực này.
            </p>
          </div>
          <div>
            <h3 className='mb-2 text-xl mt-6'>
              <span>⬤</span> Trang web này để làm gì? 🍜
            </h3>
            <Img className='drag-none size-40 sm:size-44 md:size-48 lg:size-60 float-right mb-1 ml-2 select-none rounded-md border-4 border-pink-600/50 bg-neutral-800' alt='img' src={ChillImg}></Img>
            <p className='text-slate-800/90 text-pretty'>
             - Trang web này là một không gian cá nhân nơi tôi giới thiệu bản thân, các thành tựu và dự án mà tôi đã tham gia. Tại đây, bạn có thể khám phá hành trình nghề nghiệp của tôi, bao gồm các kỹ năng chuyên môn và kinh nghiệm thực tế trong các lĩnh vực như phân tích dữ liệu, bảo mật thông tin và phát triển phần mềm. Trang web cũng cung cấp thông tin chi tiết về các công nghệ và công cụ mà tôi đã sử dụng, như React, SQL, và các phương pháp bảo mật tiên tiến. Đồng thời, đây là nơi tôi tạo cơ hội kết nối và chia sẻ ý kiến với cộng đồng. Hy vọng rằng bạn sẽ hiểu thêm về tôi qua trang web này và chúng ta có thể có cơ hội hợp tác trong tương lai.
            </p>
          </div>
        </div>
        <div>
          <div className='hidden md:block md:pl-12 lg:pl-28 xl:pl-32'>
            <div className='p-4 rounded-lg border-[2px] bg-slate-100 border-slate-200'>
              <div className='flex gap-3 items-center'>
                <div className='text-slate-700'>
                  <FontAwesomeIcon icon={faBriefcase} />
                </div>
                <p>Work</p>
              </div>
              <div className='flex mt-6 gap-3 items-center'>
                <div className='size-10 rounded-full bg-slate-800 text-slate-100 text-lg flex items-center justify-center'>
                  <FontAwesomeIcon icon={faEarthAsia} />
                </div>
                <div className='flex-1'>
                  <h5 className='text-sm'>Developer - Network Security</h5>
                  <div className='flex justify-between text-xs text-slate-700'>
                    <p>Viettel Cyber Security</p>
                    <p>2022 - now</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-4 mt-4 rounded-lg border-[2px] bg-slate-100 border-slate-200'>
              <div className='flex gap-3 items-center'>
                <div className='text-slate-700'>
                  <FontAwesomeIcon icon={faUserGraduate} />
                </div>
                <p>Education</p>
              </div>
              <div className='flex mt-6 gap-3 items-center'>
                <div className='size-10 rounded-full bg-slate-800 text-slate-100 text-lg flex items-center justify-center'>
                  <FontAwesomeIcon icon={faSchool} />
                </div>
                <div className='flex-1'>
                  <h5 className='text-sm'>Đại Học Giao Thông Vận Tải - UTH</h5>
                  <div className='flex justify-between text-xs text-slate-700'>
                    <p>Ngành: Công Nghệ Thông Tin</p>
                    <p>2024 - Now</p>
                  </div>
                </div>
              </div>
              <div className='mt-6'>
                <a href='https://ut.edu.vn' className='w-full py-2 flex text-sm hover:bg-slate-700 items-center gap-2 justify-center text-slate-100 bg-slate-800 rounded-lg'>
                  <span>
                    <FontAwesomeIcon icon={faFaceGrinBeamSweat} />
                  </span>
                  <span>Trang web của trường</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
