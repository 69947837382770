import { useEffect } from "react";
import desktop from "../../assets/imgs/desktop.png";

function Specs() {
  useEffect(() => {
    document.title = "🖥 - Spacs";
  }, []);

  const laptopSpecs = {
    laptopCpu: " Intel Core i7-13650HX (3.3GHz turbo up to 4.3GHz, 14 nhân 8 luồng, 12MB Cache, 58W)-Socket Intel LGA 1700)",
    laptopRam: "16 GBDDR5 2 khe",
    laptopGpu: "Card RTX 4050 6GB",
    laptopDisplay: "15.6 Full HD (1920 x 1080) 144Hz",
    laptopOs: "Windows 10 Pro",
    laptopDisk: "5 TB SSD NVMe PCIe 4.0  (1TB, 3.500/2.800 MB/s)",
  };

  const phoneSpecs = {
    phoneCpu: "Unisoc Tiger T820 (6 nm) 8 nhân (1x2.7 GHz & 3x2.3 GHz & 4x2.1 GHz)",
    phoneGpu: "Mali-G57 MC4",
    phoneRam: "8GB + 12GB (RAM ảo)",
    phoneDisplay: "IPS LCD, 120Hz 6.72 inches, Full HD+ (1080 x 2400 pixels) - Mật độ điểm ảnh ~392 ppi",
    phoneOs: "Android 14 - HyperOS",
    phoneDisk: "256GB, UFS 3.1",
    phoneCam: "50 MP, f/1.8 (góc rộng)",
    phonePin: "6000 mAh - Sạc nhanh 33W",
  };

  const LaptopInfo = ({ laptopCpu, laptopRam, laptopGpu, laptopDisplay, laptopOs, laptopDisk }) => (
    <>
      <ul className='list-disc text-sm ml-6'>
        <li>CPU: {laptopCpu}</li>
        <li>GPU: {laptopGpu}</li>
        <li>RAM: {laptopRam}</li>
        <li>Disk: {laptopDisk}</li>
        <li>Màn: {laptopDisplay}</li>
        <li>Os: {laptopOs}</li>
      </ul>
    </>
  );

  const PhoneInfo = ({ phoneCpu, phoneGpu, phoneRam, phoneDisplay, phoneOs, phoneDisk, phoneCam, phonePin }) => (
    <>
      <ul className='list-disc text-sm ml-6'>
        <li>CPU: {phoneCpu}</li>
        <li>GPU:{phoneGpu}</li>
        <li>RAM: {phoneRam}</li>
        <li>Disk: {phoneDisk}</li>
        <li>Màn: {phoneDisplay}</li>
        <li>Os: {phoneOs}</li>
        <li>Camera: {phoneCam}</li>
        <li>Pin, sạc: {phonePin}</li>
      </ul>
    </>
  );

  return (
    <div className='font-bold text-neutral-800 w-full pb-4'>
      <div className='mb-3 flex text-3xl gap-2 font-bold'>
        <div className='bg-neutral-800 h-[36px] w-2'></div>
        <h2>Spacs 💼</h2>
      </div>
      <p>Thiết bị tôi hiện đang sử dụng để chơi game, lập trình, học tập và hàng ngày 💻.</p>
      <div className='w-full mt-4 grid md:grid-cols-2 grid-cols-1 gap-5'>
        <div>
          <div className='mb-4 p-2 rounded-xl bg-slate-100'>
            <h4 className='mb-1 text-2xl font-bold'>
              Pc<span className='text-xl ml-2 inline-block'>(Laptop Lenovo)</span>
            </h4>
            <LaptopInfo {...laptopSpecs} />
          </div>
          <div className='p-2 rounded-xl bg-slate-100'>
            <h4 className='mb-1 text-2xl font-bold'>
              Phone <span className='text-xl ml-2 inline-block'></span>
            </h4>
            <PhoneInfo {...phoneSpecs} />
          </div>
        </div>
        <div>
          <div className='mb-4 p-2 rounded-xl bg-slate-100'>
            <h4 className='mb-1 text-2xl font-bold'>Mạng</h4>
            <ul className='list-disc text-sm ml-6'>
              <li>Mạng 4G: Itel (90k/1 month)</li>
              <li>Tốc độ download: ~120MB/s</li>
              <li>Tốc độ upload: ~70MB/s</li>
            </ul>
          </div>
          <div className='p-2 rounded-xl bg-slate-100'>
            <h4 className='mb-1 text-2xl font-bold'>Thiết Bị Khác</h4>
            <ul className='list-disc text-sm ml-6'>
              <li>Chuột: Wireless Logitech</li>
              <li>Lót chuột: Steelseries(Sách tay)</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='my-6 border-dashed border-8 border-cyan-500 rounded-3xl overflow-hidden w-11/12 rotate-[355deg] mx-auto'>
        <img src={desktop} alt='desktop.jpg' />
      </div>
    </div>
  );
}

export default Specs;
